export const ONLINE_ONLY_AUCTION = 'OnlineOnlyAttendanceMode';
export const ONLINE_TELEPHONE_AUCTION = 'OnlineTelelphoneAttendanceMode';
export const ALL_AUCTION = 'AllAttendanceMode';

export const isOnlineAuction = auctionMode => {
  return [ONLINE_ONLY_AUCTION, ONLINE_TELEPHONE_AUCTION].includes(auctionMode);
};

export const BIDDING_METHODS = {
  TELEPHONE_BIDDING: 'Telephone & Absentee Bidding',
  ONLINE_BIDDING: 'Online Bidding',
  IN_PERSON_BIDDING: 'In-Person Bidding',
};

export const AUCTIONS_TYPES_FILTERS = {
  onlineOnly: {
    display: 'Geared Online',
    value: 'Online Only',
  },
  liveAuction: {
    display: 'Live Auction',
    value: 'Live Auction',
  },
  privateSale: {
    display: 'Private Sale',
    value: 'Private Sale',
  },
};
