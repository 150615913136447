import moment from 'moment';
import qs from 'qs';
import {
  ONLINE_TELEPHONE_AUCTION,
  ONLINE_ONLY_AUCTION,
  ALL_AUCTION,
  BIDDING_METHODS,
} from 'utils/auctionTypes';
import { generateFluidImage } from 'utils/image';
import { amountFormatted } from 'shared/helpers/Currencies';

export const getFormatedDate = (list, dateKey, operation) => {
  switch (operation) {
    case 'greaterThan':
      return (
        list?.length > 0 &&
        list.reduce(function (a, b) {
          return new Date(a[dateKey]) > new Date(b[dateKey]) ? a : b;
        })
      );
    case 'lessThan':
      return (
        list?.length > 0 &&
        list.reduce(function (a, b) {
          return new Date(a[dateKey]) < new Date(b[dateKey]) ? a : b;
        })
      );
    default:
      return [];
  }
};

export const getAuctionStartAndEndDates = subEvents => {
  const auctionStartDate = new Date(
    getFormatedDate(subEvents, 'startDate', 'lessThan').startDate
  ).getTime();

  const auctionEndDate = new Date(
    getFormatedDate(subEvents, 'endDate', 'greaterThan').endDate
  ).getTime();
  return {
    auctionStartDate,
    auctionEndDate,
  };
};

export const getEventDateAverage = (subEvents, USDate = true) => {
  const startDate = moment(
    getFormatedDate(subEvents, 'startDate', 'lessThan').startDate,
    'YYYY-MM-DDTHH:mm:ssZ'
  ).toDate();

  const endDate = moment(
    getFormatedDate(subEvents, 'endDate', 'greaterThan').endDate,
    'YYYY-MM-DDTHH:mm:ssZ'
  ).toDate();

  const diffTime = Math.abs(endDate - startDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  let result = '';
  if (startDate.getDate() === endDate.getDate()) {
    result = `${startDate.toLocaleString(USDate ? 'en-US' : 'en-GB', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })}`;
  } else {
    const differentYears = startDate.getFullYear() !== endDate.getFullYear();
    result = `
    ${startDate.toLocaleString(USDate ? 'en-US' : 'en-GB', {
      month: 'long',
      day: 'numeric',
      ...(differentYears && { year: 'numeric' }),
    })}
    ${diffDays > 1 ? '-' : '&'}
    ${endDate.toLocaleString(USDate ? 'en-US' : 'en-GB', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })}
    `;
    result = result.replace(/\n/g, '');

    result = result
      .split(' ')
      .filter(function (currentItem, i, allItems) {
        return (
          i ===
          (USDate
            ? allItems.indexOf(currentItem)
            : allItems.lastIndexOf(currentItem))
        );
      })
      .join(' ');
  }
  return result;
};

export const getEventTimeAverage = subEvents => {
  let result = `
  ${moment(
    getFormatedDate(subEvents, 'startDate', 'lessThan').startDate
  ).format('hh:mm A')}
  -
  ${moment(getFormatedDate(subEvents, 'endDate', 'greaterThan').endDate).format(
    'hh:mm A'
  )}
  `;
  return result;
};

export const pastAuction = auction => {
  const auctionEvents = getAuctionEvents(auction);

  const maxDate = auctionEvents
    .map(event => event.endDate)
    .reduce((maxDate, current) => (maxDate > current ? maxDate : current), '');

  return new Date(maxDate) <= new Date();
};

export const getAuctionEventDates = auction => {
  const auctionEvents = getAuctionEvents(auction).sort(
    (d1, d2) => Date.parse(d1.startDate) - Date.parse(d2.startDate)
  );
  return auctionEvents;
};

export const getAuctionEventDatesFlat = auction => {
  const auctionEvents = getAuctionEvents(auction).sort(
    (d1, d2) => Date.parse(d1.startDate) - Date.parse(d2.startDate)
  );
  return auctionEvents.map(event => [event.startDate, event.endDate]).flat();
};

export const getAuctionEventStartDates = auction => {
  const auctionEvents = getAuctionEvents(auction).sort(
    (d1, d2) => Date.parse(d1.startDate) - Date.parse(d2.startDate)
  );
  return auctionEvents.map(event => event.startDate);
};

function getAuctionEvents(auction) {
  return (
    auction?.subEvents?.filter(
      event => event.__typename === 'ContentfulSubEventAuction'
    ) || []
  );
}

export const getCataloguePreFilteredByAuction = (
  auction,
  auctionYear,
  catalogSlug,
  upcoming = false
) => {
  let params = '';
  const sortBy = upcoming ? 'ENDING_SOONEST' : 'DEFAULT';
  const startDate = getFormatedDate(auction.subEvents, 'startDate', 'lessThan')
    .startDate;

  switch (auction.eventAttendanceMode) {
    case ALL_AUCTION:
      params = {
        filtersInput: {
          liveAuctionVenue: [auction.location.name],
          auctionYear: [auctionYear],
          auctionType: ['Live Auction'],
        },
        sortBy: sortBy,
      };
      break;
    case ONLINE_ONLY_AUCTION:
    case ONLINE_TELEPHONE_AUCTION:
      params = {
        filtersInput: {
          auctionType: ['Online Only'],
          auctionYear: [auctionYear],
          onlineAuctionName: [
            `${moment(startDate).utc().valueOf()}_${auction.name}`,
          ],
        },
        sortBy: sortBy,
      };
      break;
    default:
      params = '';
  }

  return `/${catalogSlug}/?${qs.stringify(params)}`;
};

export const getTotalPrices = (list, shipping = 0) => {
  if (list?.length > 0) {
    const cost = list.reduce((acc, current) => acc + current, 0);
    return {
      subTotal: amountFormatted(cost),
      total: amountFormatted(cost + shipping),
    };
  }
  return {
    subTotal: amountFormatted(shipping),
    total: amountFormatted(shipping),
  };
};

export const formatAuctions = auctions => {
  return auctions
    ?.map(auction => {
      const auctionStartEndDates = getAuctionStartAndEndDates(
        auction.subEvents
      );
      return {
        ...auction,
        id: auction.objectID,
        name: auction.name,
        date: getEventDateAverage(auction.subEvents),
        imgSrc: auction.fluidAuctionImage
          ? generateFluidImage(
              350,
              auction?.fluidAuctionImage?.file?.url,
              90,
              auction?.fluidAuctionImage?.file?.details?.image?.width,
              auction?.fluidAuctionImage?.file?.details?.image?.height
            )
          : null,
        status: auction.eventStatus,
        eventAttendanceMode: auction.eventAttendanceMode,
        offers: auction.offers,
        startDate: auctionStartEndDates?.auctionStartDate,
        endDate: auctionStartEndDates?.auctionEndDate,
      };
    })
    .filter(auction => {
      return (
        auction.status !== 'EventCancelled' &&
        auction.status !== 'EventPostponed' &&
        auction.status !== 'EventAnnounced'
      );
    });
};

export const filterByBiddingMethod = auctions => {
  const filtered = formatAuctions(auctions);
  const online = [];
  const inPerson = [];
  const tele = [];

  filtered?.length > 0 &&
    filtered.forEach(auction => {
      switch (auction.eventAttendanceMode) {
        case ONLINE_ONLY_AUCTION:
          online.push(auction);
          break;
        case ONLINE_TELEPHONE_AUCTION:
          online.push(auction);
          tele.push(auction);
          break;
        case ALL_AUCTION:
          online.push(auction);
          tele.push(auction);
          if (auction.offers) inPerson.push(auction);
          break;
        default:
          return;
      }
    });
  return {
    [BIDDING_METHODS.ONLINE_BIDDING]: online,
    [BIDDING_METHODS.IN_PERSON_BIDDING]: inPerson,
    [BIDDING_METHODS.TELEPHONE_BIDDING]: tele,
  };
};

export const getNearestAuction = auctions => {
  const nearestAuction =
    auctions?.length > 0
      ? auctions
          .sort((a, b) => {
            const [aDate, bDate] = [a.endDate, b.endDate].map(d =>
              Math.abs(new Date(d) - new Date())
            );
            return aDate - bDate;
          })
          .filter(auction => !pastAuction(auction))
      : [];

  return nearestAuction?.length > 0 ? nearestAuction[0] : null;
};
